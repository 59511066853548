<template>
  <main id="OrderFinish">
    <div class="row">
      <div class="content">
        <h3>訂單已建立</h3>
        <h4>
          感謝您的支持！您是第<b style="color: red">
            {{ $route.query.count }} </b
          >個加入的成員！<br />我們將在訂單確認後指派專人與您聯絡！
        </h4>
        <p>
          您的訂單編號為：<span>{{ $route.query.id }}</span>
        </p>
        <p>
          若您有任何疑問歡迎<a href="https://line.me/R/ti/p/%40334xpyda"
            >加入官方LINE@</a
          >詢問喔！。
        </p>

        <div class="btn_box">
          <a
            href="https://line.me/R/ti/p/%40334xpyda"
            target="_blank"
            class="fb_btn"
            >加入官方LINE@</a
          >
          <router-link to="/" class="back_btn">回首頁</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'OrderFinish',
  mixins: [SmoothScrollResister],
  data() {
    return {
      meta_data: null,
    };
  },
  mounted() {
    this.RegisterGsap('OrderFinish');
    this.meta_data = GetMetaData('訂單已建立', '', '');
          window.prerenderReady = true;
  },
  created() {
    this.$route.query.id ? '' : this.$router.push('/');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
